<template>
    <v-app class="desktopLayoutClass">
        <div>
            <div
                v-show="show_header"
                class="header"
                style="
                display: flex;
                flex-direction: row;
                padding-top: 10px;
                padding-left: 5px;"
                >
                <div class="bookAppointmentMainDiv" style="display: flex; flex-direction: row; width: 100%">
                    <div
                    style="width: 10%; padding-top: 6px"
                    align="left"
                    v-on:click="redirectToProfilePage()"
                    >
                        <img src="https://img.icons8.com/material-outlined/20/000000/left.png"/>
                    </div>
                    <div style="width: 90%" align="left">
                        <span style="font-weight: 600; font-size: 15pt">{{$t("Customer.MySubscription.My_Subscription")
                        }}</span>
                    </div>
                </div>
            </div>
            <div style="display:flex; justify-content: center; flex-direction: column; padding-bottom: 70px;">

                <div v-if="activeSubscriptions.length > 0 && !isLoading">
                    <div class="subscriptionBackgroundClass" v-for="subscription_data in activeSubscriptions" @click="redirectToViewSub(subscription_data)">
                        <div class="subscriptionPackageName">
                            <h3> <span style="color:#F89035;">{{ subscription_data.corporate_program_name }}</span> {{ $t("Customer.MySubscription.Package") }}</h3>
                        </div>

                        <div class="subscriptionPackageExpiresTitle">
                            <p>{{ subscription_data.description }}</p>
                        </div>

                        <div class="bookingLeftMain">
                            <div class="bookingLeftDataClass">
                                {{$t("Customer.MySubscription.Booking_Left")}}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="expiredSubscriptions.length > 0 && !isLoading">
                    <div class="ExpiredsubscriptionBackgroundClass" v-for="subscription_data in expiredSubscriptions" @click="redirectToViewSub(subscription_data)">
                        <div class="subscriptionPackageName">
                            <h3> <span style="color:#F89035;">{{ subscription_data.corporate_program_name }}</span> {{ $t("Customer.MySubscription.Package") }}</h3>
                        </div>

                        <div class="subscriptionPackageExpiresTitle">
                            <p>{{ subscription_data.description }}</p>
                        </div>

                        <div class="bookingLeftMain">
                            <div class="bookingLeftDataClass">
                                {{$t("Customer.MySubscription.Booking_Left")}}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="!isLoading && expiredSubscriptions.length == 0 && activeSubscriptions.length == 0">
                    <p style="padding-top: 18px; font-weight:500">{{ $t("Customer.MySubscription.No_Active_Subscription") }}</p>
                </div>
                <div v-else-if= "isLoading">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                </div>
                <!-- <div v-if="allSubs.length > 0 && !isLoading">
                    <div class="subscriptionBackgroundClass" v-for="subscription_data in allSubs" @click="redirectToViewSub(subscription_data)">
                        <div class="subscriptionPackageName">
                            <h3> <span style="color:#F89035;">{{ subscription_data.CorporatePackageData[0].corporate_program_name }}</span> {{ $t("Customer.MySubscription.Package") }}</h3>
                        </div>
                        <div class="subscriptionPackageExpiresTitle">
                            <p>{{ subscription_data.CorporatePackageData[0].description }}</p>
                        </div>
                        <div class="bookingLeftMain">
                            <div class="bookingLeftDataClass">
                                {{$t("Customer.MySubscription.Booking_Left")}}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="allSubs.length == 0 && !isLoading">
                    <p style="padding-top: 18px; font-weight:500">{{ $t("Customer.MySubscription.No_Active_Subscription") }}</p>
                </div>
                <div v-else>
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                </div> -->
            </div>
        </div>
    </v-app>
</template>
<script>
import axios from "axios";
export default{
    data(){
        return{
            pageName: "ViewAllSubscriptions",
            currentCustomer:"",
            show_header: true,
            expiredSubscriptions : [],
            activeSubscriptions : [],
            allSubs: [],
            isLoading: true
        }
    },
    mounted(){
        this.currentCustomer = this.$cookies.get("customerToken");
        var access = this.$cookies.get("access");
        if(!this.currentCustomer){
            this.$router.push({
            name : "Login"
            })
        }
        else{
            if(this.$store.state.locale == "en"){
            document.title = "My Subscriptions"
            }
            else{
            document.title = "စာရင်းသွင်းထားသောအစီအစဥ်များ"
            }
            var userAgent = navigator.userAgent;
            if(userAgent.includes("kbzpay")){
            this.show_header = false;
            }
            if(this.$store.state.locale !== "")
            {
                this.$i18n.locale =  this.$store.state.locale;
            }
            else
            {
                this.$i18n.locale = "mm";
                this.$store.dispatch("changeLocale", this.$i18n.locale);
            }
            var susbscriptionBody ={
                token: this.currentCustomer
            };
            axios
            .post(
            process.env.VUE_APP_BACKEND_URL + "/customerSubscriptionsPage",
            susbscriptionBody
            )
            .then((customerSubscriptionsPageResponse)=>{
                this.allSubs = customerSubscriptionsPageResponse.data.subscription_data;
                this.expiredSubscriptions = customerSubscriptionsPageResponse.data.expired_data;
                this.activeSubscriptions = customerSubscriptionsPageResponse.data.customer_name == "Guest" ? [] : customerSubscriptionsPageResponse.data.subscription_data;
                this.isLoading = false;
                this.userPageViewTable();
            })
            .catch((customerSubscriptionsPageError)=>{
                console.log("customerSubscriptionsPageError",customerSubscriptionsPageError);
                this.isLoading = false;
            })
        }
    },
    methods:{
        redirectToViewSub(sub_data)
        {
            this.$router.push({
                path: "/customer/myParticularSubscription/"+sub_data._id
            });
        },
        redirectToProfilePage(){
            this.$router.go(-1);
        },
        userPageViewTable() {
            if (this.currentCustomer === null) {
                var userPageViewTableBody = {
                pageName : 'mySubscription',
                typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                pageName: 'mySubscription',
                typeOfUser: 'CUSTOMER',
                token: this.currentCustomer
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
    },
    }
}
</script>
<style>
.subscriptionBackgroundClass{
    background-image: url("https://s3iconimages.mymedicine.com.mm/blue_banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: center; */
    width: 90%;
    height: 144px;
    /* margin-right: auto;
    margin-left: auto; */
    margin-top: 25px;
    margin-left: 20px;
    border-radius: 8px;
}
.subscriptionBackgroundClass{
    color: white;
    text-align: left;
    padding: 12px  0px 12px 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    position: relative;
}
.ExpiredsubscriptionBackgroundClass{
    background-image: linear-gradient(grey,black), url("https://s3iconimages.mymedicine.com.mm/blue_banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: saturation;
    /* background-position: center; */
    width: 90%;
    height: 144px;
    /* margin-right: auto;
    margin-left: auto; */
    margin-top: 25px;
    margin-left: 20px;
    border-radius: 8px;
}
.ExpiredsubscriptionBackgroundClass{
    color: white;
    text-align: left;
    padding: 12px  0px 12px 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    position: relative;
}
.subscriptionPackageExpiresTitle{
    color: white;
    letter-spacing:1px;
    text-align: left;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-top:4px
}
.subscriptionPackageExpiresDate{
    color: white;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.bookingLeftDataClass{
    background: #F89035;
    border-bottom-right-radius:8px;
    border-top-left-radius:8px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.5px;
    padding: 2px 12px;
    /* display: flex;
    justify-content: end; */
}
.bookingLeftMain{
    /* background: #F89035; */
    display: flex;
    justify-content:flex-end;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;

}
</style>
